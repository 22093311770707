import 'pdfjs-dist/webpack';
import { MyPDFFindController, MyTextHighlighter } from '@/views/pdfjsHelpers';
import { PDFPageView } from 'pdfjs-dist/web/pdf_viewer';

let pdfViewer = null;

// Override PDFPageView _textHighlighter getter so we can switch it out
// with our custom textHighlighter
Object.defineProperty(PDFPageView.prototype, '_textHighlighter', {
  get: function() {
    return new MyTextHighlighter({
      pageIndex: this.id - 1,
      eventBus: pdfViewer.eventBus,
      findController: pdfViewer.findController,
    })
  }
});


const viewer = {
  namespaced: true,
  state: {
    caseId: null,
    documentId: null,
    projectCaseId: null,
    highlightPageIds: [],
    customSearches: {},
  },
  mutations: {
    setCaseId(state, caseId) {
      state.caseId = caseId;
    },
    setProjectCaseId(state, projectCaseId) {
      state.projectCaseId = projectCaseId;
    },
    setDocumentId(state, documentId) {
      state.documentId = documentId;
    },
    setHighlightPageIds(state, pageIds) {
      state.highlightPageIds = pageIds;
    },
    setPageCustomSearches(state, { pagenum, searches }) {
      state.customSearches = {
        ...state.customSearches,
        [pagenum]: { ...searches },
      };
    },
  },
  actions: {
    setCaseId({ commit, dispatch }, caseId) {
      return new Promise((resolve, reject) => {
        commit('setCaseId', caseId);
        dispatch('loadCase', caseId, { root: true })
          .then(resolve)
          .catch(reject);
      });
    },
    setProjectCaseId({ commit }, projectCaseId) {
      return new Promise((resolve) => {
        commit('setProjectCaseId', projectCaseId);
        resolve();
      });
    },
    setDocumentId({ commit }, documentId) {
      return new Promise((resolve) => {
        commit('setDocumentId', documentId);
        resolve();
      });
    },
    setHighlightPageIds({ commit }, pageIds) {
      return new Promise((resolve) => {
        commit('setHighlightPageIds', pageIds);
        resolve();
      });
    },
    addPageCustomSearches({ commit, state }, { pagenum, searches }) {
      return new Promise((resolve) => {
        let newSearches = state.customSearches[pagenum] || {};
        for (let key in searches) {
          newSearches[key] = searches[key];
        }
        commit('setPageCustomSearches', { pagenum, searches: newSearches });
        resolve();
      });
    },
    removePageCustomSearches({ commit, state }, { pagenum, searchTerms}) {
      return new Promise((resolve) => {
        let remaining = { ...state.customSearches[pagenum] } || {};
        remaining = Object.fromEntries(
          Object.entries(remaining).filter(([key]) => !searchTerms.includes(key))
        );
        commit('setPageCustomSearches', { pagenum, searches: remaining });
        resolve();
      });
    },
    clearCustomSearches({ commit , state }) {
      return new Promise((resolve) => {
        for (let pagenum of state.customSearches) {
          commit('setPageCustomSearches', { pagenum, searches: {} });
        }
        resolve();
      });
    },
    setViewer(_, viewer) {
      // Custom Find Controller for custom highlighting
      viewer.findController = new MyPDFFindController({
        eventBus: viewer.eventBus,
        linkService: viewer.linkService
      });
      pdfViewer = viewer;
    },
    clearViewer() {
      pdfViewer = null;
    },
    getViewer() {
      return pdfViewer;
    }
  },
  getters: {
    caseId: (state) => state.caseId,
    projectCaseId: (state) => state.projectCaseId,
    documentId: (state) => state.documentId,
    projectId: (state, getters) => {
      if (!getters.projectCase) {
        return null;
      }
      return getters.projectCase.project;
    },
    case_: (state, getters, rootState, rootGetters) => {
      return rootGetters.getCase(state.caseId);
    },
    document_: (state, getters, rootState, rootGetters) => {
      return rootGetters.getDocument(state.documentId);
    },
    documentPages: (state, getters, rootState, rootGetters) => {
      return rootGetters.getDocumentPages(state.documentId);
    },
    pageIds: (state, getters, rootState, rootGetters) => {
      if (!(getters.document_)) {
        return [];
      }
      return rootGetters.getPageIdsForDocument(getters.document_.id);
    },
    documentExcerpts: (state, getters, rootState, rootGetters) => {
      if (!getters.document_) {
        return [];
      }
      return getters.document_.excerpts
        .map((eId) => rootGetters.getExcerpt(eId));
    },
    projectCase: (state, getters, rootState, rootGetters) => {
      return rootGetters.getProjectCase(state.projectCaseId);
    },
    project: (state, getters, rootState, rootGetters) => {
      return rootGetters.getProject(getters.projectId);
    },
    referenceTypes: (state, getters, rootState, rootGetters) => {
      if (!getters.projectId) {
        return [];
      }
      return rootGetters.getReferenceTypesForProject(getters.projectId);
    },
    references: (state, getters, rootState, rootGetters) => {
      return rootGetters.getProjectDocumentReferences({
        projectId: getters.projectId,
        documentId: getters.documentId,
      });
    },
    matches: (state, getters, rootState, rootGetters) => {
      return rootGetters.getDocumentProjectMatches({
        documentId: getters.documentId,
        projectId: getters.projectId,
      });
    },
    highlightPageIds: (state) => state.highlightPageIds,
    pdfViewer: () => pdfViewer,
    customSearches: (state) => {
      return state.customSearches;
    },
  },
};

export default viewer;
